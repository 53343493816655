import React from "react"
import PropTypes from "prop-types"

export const CheckoutStepsContainer = ({ children }) => {
  return (
    <main className="wrapper-width wrapper-height-small checkout-steps-container">
      <div className="checkout-steps">{children}</div>
    </main>
  )
}

CheckoutStepsContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
