import React from "react"
import PropTypes from "prop-types"

export const DataDisplay = ({ children, label }) => (
  <div className="data-display">
    <label className="data-display-label">{label}</label>
    <div className="data-display-data">{children}</div>
  </div>
)

DataDisplay.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
}

export const DataDisplayPhoto = ({ photoUrl, photoDesc }) => (
  <img
    className="data-display-photo"
    src={photoUrl}
    alt={photoDesc}
    alt={photoDesc}
  />
)

DataDisplayPhoto.propTypes = {
  photoUrl: PropTypes.string.isRequired,
  photoDesc: PropTypes.string.isRequired,
}
