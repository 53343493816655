import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import BlobWide from "../../../images/Blob-wide.svg"

export const BoilerBlob = ({
  blobColor,
  photo,
  photoDesc,
  award,
  awardDesc,
  useGraphql,
}) => {
  if (useGraphql) {
    return (
      <div className="boiler-blob">
        <BlobWide className={blobColor} />
        <Img
          className="boiler-photo"
          fluid={photo}
          alt={photoDesc}
          title={photoDesc}
        />

        {award && awardDesc && award.fluid && (
          <Img
            className="boiler-award"
            fluid={award}
            alt={awardDesc}
            title={awardDesc}
          />
        )}
      </div>
    )
  } else {
    return (
      <div className="boiler-blob">
        <BlobWide className={blobColor} />
        <img
          className="boiler-photo"
          src={photo}
          alt={photoDesc}
          title={photoDesc}
        />
        <img
          className="boiler-photo"
          src={photo}
          alt={photoDesc}
          title={photoDesc}
        />
        {award && awardDesc && (
          <img
            className="boiler-award"
            src={award}
            alt={awardDesc}
            title={awardDesc}
          />
        )}
      </div>
    )
  }
}

BoilerBlob.defaultProps = {
  award: {},
  awardDesc: "",
  blobColor: "green",
  photo: {},
  photoDesc: "",
  useGraphql: true,
}

BoilerBlob.propTypes = {
  award: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  awardDesc: PropTypes.string,
  blobColor: PropTypes.oneOf(["green", "blue-light", "yellow"]),
  photo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  photoDesc: PropTypes.string,
  useGraphql: PropTypes.bool,
}
