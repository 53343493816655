import React from "react"
import PropTypes from "prop-types"

import { NavMain } from "./NavMain"
import { NavMinimal } from "./NavMinimal"

export { SubNav } from "./SubNav"
export { NavMinimal }

export const Nav = ({ minimal }) => {
  if (minimal) {
    return <NavMinimal />
  } else {
    return <NavMain />
  }
}

Nav.propTypes = {
  minimal: PropTypes.bool.isRequired,
}
