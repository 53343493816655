import React from "react"
import PropTypes from "prop-types"

export const Label = ({ name, children, optional }) => (
  <label className="label" htmlFor={name}>
    <span>{children}</span>
    {optional && <span className="optional">Optional</span>}
  </label>
)

Label.defaultProps = {
  optional: false,
}

Label.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  optional: PropTypes.bool,
}
