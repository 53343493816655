import React from "react"
import PropTypes from "prop-types"

import { useField } from "formik"

export const Checkbox = ({ name, label }) => {
  const [field] = useField(name)

  return (
    <div className="form-element-full-width checkbox">
      <input type="checkbox" name={name} id={name} {...field} />
      <span className="checkbox-checkmark"></span>
      <label className="checkbox-label" htmlFor={name}>
        {label}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
