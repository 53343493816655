import React from "react"
import PropTypes from "prop-types"

import { FooterMain } from "./FooterMain"
import { FooterMinimal } from "./FooterMinimal"

export { FooterMain }

export const Footer = ({ minimal }) => (
  <footer className="wrapper-width footer">
    {minimal ? <FooterMinimal /> : <FooterMain />}
  </footer>
)

Footer.defaultProps = {
  minimal: false,
}

Footer.propTypes = {
  minimal: PropTypes.bool,
}
