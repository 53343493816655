import React from "react"
import PropTypes from "prop-types"

export const IconButton = ({ icon, func, name, ...props }) => (
  <button
    type="button"
    className="icon-button"
    onClick={func}
    aria-label={name}
    {...props}
  >
    <span className={`icon-${icon}`}></span>
  </button>
)

IconButton.defaultProps = {
  icon: "menu-open",
}

IconButton.propTypes = {
  icon: PropTypes.string,
  func: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}
