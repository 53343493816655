export const defaultRequiredText = "Please enter into this field"

export const availablePostcodes = [
  "BB",
  "BD",
  "BL",
  "CA",
  "DH",
  "DL",
  "FY",
  "FY",
  "HD",
  "HG",
  "HU",
  "HX",
  "LA",
  "LS",
  "NE",
  "OL",
  "PR",
  "SR",
  "TS",
  "WF",
  "WN",
  "YO",
]
