import React from "react"
import PropTypes, { bool } from "prop-types"

export const BreakDownTable = ({ rows, total, returnFree, useStrings }) => (
  <section className="breakdown-table-wrapper">
    <table className="breakdown-table">
      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            <td className="breakdown-table-row-label">{row.label}</td>
            <td className="breakdown-table-row-price">
              {useStrings
                ? row.price
                : returnFree && row.price === 0
                ? "FREE"
                : "£" + row.price.toFixed(2)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {total !== 0 ? (
      <div className="breakdown-table-total">
        <span className="breakdown-table-total-label">Total</span>
        <span className="breakdown-table-total-value">
          {useStrings ? total : `£${total.toFixed(2)}`}
        </span>
      </div>
    ) : (
      ""
    )}
  </section>
)

BreakDownTable.defaultProps = {
  returnFree: false,
  useStrings: false,
  total: 0,
}

BreakDownTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired
  ).isRequired,
  returnFree: bool,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  useStrings: PropTypes.bool,
}
