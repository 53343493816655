import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import PropTypes from "prop-types"

import { ButtonWithLoader, FormSection, Form, Input, Label } from "../../"
import { defaultRequiredText } from "../../../../constants"
import { useOnClickOutside } from "../../../../hooks"

export const AddressLookup = ({
  successFunc,
  updateExistingData,
  existingData,
  nextButtonText,
}) => {
  const [error, setError] = React.useState("")
  const [searchPostcode, setSearchPostcode] = React.useState("")
  const [postcodeAddresses, setPostcodeAddresses] = React.useState([])
  const [isGettingAddresses, setIsGettingAddresses] = React.useState(false)
  const [
    postcodeAddressesVisible,
    setPostcodeAddressesVisible,
  ] = React.useState(false)

  const firstAddress = React.useRef()
  const ref = React.useRef()
  useOnClickOutside(ref, () => setPostcodeAddressesVisible(false))

  const findAddresses = async () => {
    setIsGettingAddresses(true)
    const formattedPostcode = searchPostcode.replace(/ /g, "").toLowerCase()

    try {
      const addresses = await axios({
        method: "get",
        url: `https://api.getAddress.io/find/${formattedPostcode}?api-key=Ryi8fP4gzU6weZRxzYZA2w29744&expand=true&sort=true`,
      })

      const response = addresses.data.addresses

      if (response.length > 0) {
        setError("")
        setPostcodeAddresses(response)
        setPostcodeAddressesVisible(true)
        firstAddress.current.focus()
      } else {
        setError("Could not find addresses, please enter address manually")
      }

      setIsGettingAddresses(false)
    } catch (error) {
      setError("Could not find addresses, please enter address manually")
      setIsGettingAddresses(false)
    }
  }

  const inputAddress = (addressObj, setFieldValue) => {
    const { line_1, line_2, town_or_city, county } = addressObj

    line_1 && setFieldValue("line1", line_1, true)
    line_2 && setFieldValue("line2", line_2, true)
    town_or_city && setFieldValue("city", town_or_city, true)
    county && setFieldValue("region", county, true)
    setFieldValue("postalCode", searchPostcode, true)
    setPostcodeAddressesVisible(false)
    setPostcodeAddresses([])
  }

  const findAddressesOnEnter = (e, findAddresses) => {
    if (e.key === "Enter") {
      e.preventDefault()
      findAddresses()
    }
  }

  return (
    <Formik
      initialValues={{
        line1: "",
        line2: "",
        city: "",
        region: "",
        postalCode: "",
      }}
      validationSchema={Yup.object().shape({
        line1: Yup.string().required(defaultRequiredText),
        line2: Yup.string(),
        city: Yup.string().required(defaultRequiredText),
        region: Yup.string().required(defaultRequiredText),
        postalCode: Yup.string().required(defaultRequiredText),
      })}
      onSubmit={(values, { setSubmitting }) => {
        successFunc(values, setSubmitting, existingData, updateExistingData)
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <div className="form">
            <FormSection>
              <section>
                <div className={`input${error ? " form-error" : ""}`}>
                  <Label name="search-postcode">Search postcode</Label>
                  <div className="address-lookup-field">
                    <input
                      className="input-field"
                      name="search-postcode"
                      id="search-postcode"
                      value={searchPostcode}
                      onChange={(e) => setSearchPostcode(e.target.value)}
                      onKeyPress={(e) => findAddressesOnEnter(e, findAddresses)}
                    />

                    <div
                      className={`address-lookup-results${
                        postcodeAddresses.length > 0 && postcodeAddressesVisible
                          ? " active"
                          : ""
                      }`}
                      ref={ref}
                    >
                      {postcodeAddresses.map((address, index) => (
                        <button
                          ref={index === 0 ? firstAddress : null}
                          className="address-lookup-result"
                          key={address.line_1}
                          type="button"
                          onClick={() => inputAddress(address, setFieldValue)}
                        >
                          {address.line_1 + ", " + address.town_or_city}
                        </button>
                      ))}
                    </div>
                  </div>
                  <span className="form-error-text">{error}</span>
                </div>
                <ButtonWithLoader
                  additionalClasses="green address-lookup-button"
                  type="button"
                  onClick={findAddresses}
                  disabled={isGettingAddresses}
                >
                  Find addresses
                </ButtonWithLoader>
              </section>
            </FormSection>
            <FormSection>
              <Input name="line1" label="Street address" />
              <Input name="line2" label="Area" optional={true} />
              <Input name="city" label="Town/city" />
              <Input name="region" label="County" />
              <Input name="postalCode" label="Postcode" />
            </FormSection>
          </div>
          <ButtonWithLoader
            additionalClasses="button-checkout"
            disabled={isSubmitting}
            type="submit"
          >
            {nextButtonText}
          </ButtonWithLoader>
        </Form>
      )}
    </Formik>
  )
}

AddressLookup.propTypes = {
  successFunc: PropTypes.func.isRequired,
  updateExistingData: PropTypes.func.isRequired,
  existingData: PropTypes.object.isRequired,
  nextButtonText: PropTypes.string.isRequired,
}
