import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import PropTypes from "prop-types"
import { useSnackbar } from "react-simple-snackbar"

import {
  ButtonGroup,
  ButtonWithLoader,
  Checkbox,
  CheckboxGroup,
  Form,
  FormPage,
  FormSection,
  Input,
  Label,
  SnackBarOptions,
  TextArea,
} from "../.."

import { defaultRequiredText } from "../../../../constants"
import { useOnClickOutside } from "../../../../hooks"

export const RequestACallbackForm = ({
  setCallbackSubmitted,
  setCallbackData,
}) => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const [error, setError] = React.useState("")
  const [searchPostcode, setSearchPostcode] = React.useState("")
  const [postcodeAddresses, setPostcodeAddresses] = React.useState([])
  const [isGettingAddresses, setIsGettingAddresses] = React.useState(false)
  const [
    postcodeAddressesVisible,
    setPostcodeAddressesVisible,
  ] = React.useState(false)

  const firstAddress = React.useRef()
  const ref = React.useRef()
  useOnClickOutside(ref, () => setPostcodeAddressesVisible(false))

  const findAddresses = async () => {
    setIsGettingAddresses(true)
    const formattedPostcode = searchPostcode.replace(/ /g, "").toLowerCase()

    try {
      const addresses = await axios({
        method: "get",
        url: `https://api.getAddress.io/find/${formattedPostcode}?api-key=Ryi8fP4gzU6weZRxzYZA2w29744&expand=true&sort=true`,
      })

      const response = addresses.data.addresses

      if (response.length > 0) {
        setError("")
        setPostcodeAddresses(response)
        setPostcodeAddressesVisible(true)
        firstAddress.current.focus()
      } else {
        setError("Could not find addresses, please enter address manually")
      }

      setIsGettingAddresses(false)
    } catch (error) {
      console.log(error, error.response)
      setError("Could not find addresses, please enter address manually")
      setIsGettingAddresses(false)
    }
  }

  const inputAddress = (addressObj, setFieldValue) => {
    const { line_1, line_2, town_or_city, county } = addressObj

    line_1 && setFieldValue("address.line1", line_1, true)
    line_2 && setFieldValue("address.line2", line_2, true)
    town_or_city && setFieldValue("address.city", town_or_city, true)
    county && setFieldValue("address.region", county, true)
    setFieldValue("address.postalCode", searchPostcode, true)
    setPostcodeAddressesVisible(false)
    setPostcodeAddresses([])
  }

  const findAddressesOnEnter = (e, findAddresses) => {
    if (e.key === "Enter") {
      e.preventDefault()
      findAddresses()
    }
  }

  const formatValues = async (values) => {
    let servicesArray = []

    values.services.boilers &&
      servicesArray.push("Boiler repairs, servicing or installation")
    values.services.centralHeating &&
      servicesArray.push("Central heating, servicing or installations")
    values.services.plumbing &&
      servicesArray.push("Plumbing reapirs, servicing or installations")
    values.services.electrics &&
      servicesArray.push("Electrical, servicing and installations")
    values.services.landlordServices &&
      servicesArray.push("Services for landlords")

    delete values.services

    return { ...values, servicesRequired: servicesArray }
  }

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const stashedServices = values.services
    const formattedValues = await formatValues(values)
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.GATSBY_API_BASE_URL}/callback-request/send`,
        data: formattedValues,
      })
      resetForm({})
      setCallbackData(response.data)
      setCallbackSubmitted(true)
    } catch (error) {
      console.log(error)
      console.log(error.response)
      values.services = stashedServices
      openSnackbar("Could not request callback, please try again")
    }
    setSubmitting(false)
  }

  return (
    <FormPage>
      <header className="form-page-header">
        <h1 className="heading-large">Request a callback</h1>
        <p>Get in touch and we’ll contact you within 2 hours</p>
      </header>
      <main>
        <Formik
          initialValues={{
            services: {
              boilers: false,
              centralHeating: false,
              plumbing: false,
              electrics: false,
              landlordServices: false,
            },
            howWeCanHelp: "",
            urgency: "",
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            homePhone: "",
            goodTimeToCall: "",
            address: {
              line1: "",
              line2: "",
              city: "",
              region: "",
              postalCode: "",
            },
          }}
          validationSchema={Yup.object().shape({
            services: Yup.object().shape({
              boilers: Yup.boolean().oneOf([true, false]),
              centralHeating: Yup.boolean().oneOf([true, false]),
              plumbing: Yup.boolean().oneOf([true, false]),
              electrics: Yup.boolean().oneOf([true, false]),
              landlordServices: Yup.boolean().oneOf([true, false]),
            }),
            howWeCanHelp: Yup.string().required(
              "Please tell us how we can fix the problem with your home"
            ),
            urgency: Yup.string()
              .oneOf(["urgent", "needing-soon", "just-looking"])
              .required(
                "Please tell us how urgent the work on your property is"
              ),

            firstName: Yup.string().required("Please tell us your first name"),
            lastName: Yup.string().required("Please tell us your last name"),
            email: Yup.string()
              .email("Please enter a valid email address")
              .required("Please enter your email address"),
            mobile: Yup.string().required("Please tell us your mobile number"),
            homePhone: Yup.string(),
            goodTimeToCall: Yup.string().required(
              "Please enter when is a good time to call you back"
            ),
            address: Yup.object().shape({
              line1: Yup.string().required(
                "Please enter your house number and street name"
              ),
              line2: Yup.string(),
              city: Yup.string().required(
                "Please enter your nearest town/city"
              ),
              region: Yup.string().required(
                "Please enter the county you live in"
              ),
              postalCode: Yup.string().required("Please enter your postcode"),
            }),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="form">
                <FormSection>
                  <CheckboxGroup>
                    <span className="label">
                      <span>
                        Services required{" "}
                        <span className="label-helper-text">
                          (Tick all that apply)
                        </span>
                      </span>
                    </span>
                    <Checkbox
                      name="services.boilers"
                      label="Boiler repairs, servicing or installation"
                    />
                    <Checkbox
                      name="services.centralHeating"
                      label="Central heating, servicing or installations "
                    />
                    <Checkbox
                      name="services.plumbing"
                      label="Plumbing repairs, servicing or installations"
                    />
                    <Checkbox
                      name="services.electrics"
                      label="Electrical, servicing and installations"
                    />
                    <Checkbox
                      name="services.landlordServices"
                      label="Services for landlords"
                    />
                  </CheckboxGroup>
                  <TextArea name="howWeCanHelp" label="How can we help?" />
                  <ButtonGroup
                    name="urgency"
                    label="How urgent is this work?"
                    options={[
                      {
                        id: "1",
                        value: "urgent",
                        label: "Urgent",
                      },
                      {
                        id: "2",
                        value: "needing-soon",
                        label: "Needing soon",
                      },
                      {
                        id: "3",
                        value: "just-looking",
                        label: "Just looking",
                      },
                    ]}
                  />
                </FormSection>
                <FormSection>
                  <Input name="firstName" label="First name" />
                  <Input name="lastName" label="Last name" />
                  <Input name="email" label="Email" type="phone" />
                  <Input name="mobile" label="Mobile" type="phone" />
                  <Input name="homePhone" label="Home phone" optional={true} />
                  <TextArea
                    name="goodTimeToCall"
                    label="When is the best time for us to call?"
                  />
                </FormSection>
                <FormSection>
                  <section>
                    <div className={`input${error ? " form-error" : ""}`}>
                      <Label name="search-postcode">Search postcode</Label>
                      <div className="address-lookup-field">
                        <input
                          className="input-field"
                          name="search-postcode"
                          id="search-postcode"
                          value={searchPostcode}
                          onChange={(e) => setSearchPostcode(e.target.value)}
                          onKeyPress={(e) =>
                            findAddressesOnEnter(e, findAddresses)
                          }
                        />

                        <div
                          className={`address-lookup-results${
                            postcodeAddresses.length > 0 &&
                            postcodeAddressesVisible
                              ? " active"
                              : ""
                          }`}
                          ref={ref}
                        >
                          {postcodeAddresses.map((address, index) => (
                            <button
                              ref={index === 0 ? firstAddress : null}
                              className="address-lookup-result"
                              key={address.line_1}
                              type="button"
                              onClick={() =>
                                inputAddress(address, setFieldValue)
                              }
                            >
                              {address.line_1 + ", " + address.town_or_city}
                            </button>
                          ))}
                        </div>
                      </div>
                      <span className="form-error-text">{error}</span>
                    </div>
                    <ButtonWithLoader
                      additionalClasses="green address-lookup-button"
                      type="button"
                      onClick={findAddresses}
                      disabled={isGettingAddresses}
                    >
                      Find addresses
                    </ButtonWithLoader>
                  </section>
                </FormSection>
                <FormSection>
                  <Input name="address.line1" label="Street address" />
                  <Input name="address.line2" label="Area" optional={true} />
                  <Input name="address.city" label="Town/city" />
                  <Input name="address.region" label="County" />
                  <Input name="address.postalCode" label="Postcode" />
                </FormSection>
              </div>
              <ButtonWithLoader
                disabled={isSubmitting}
                additionalClasses="button-submit"
                type="submit"
              >
                Request callback
              </ButtonWithLoader>
            </Form>
          )}
        </Formik>
      </main>
    </FormPage>
  )
}

RequestACallbackForm.propTypes = {
  setCallbackSubmitted: PropTypes.func.isRequired,
  setCallbackData: PropTypes.func.isRequired,
}
