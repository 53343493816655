import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { MobileMenuSubServicesItem } from "./MobileMenuSubServicesItem"

export const MobileMenuServicesItem = ({
  mobileMenuServicesHeight,
  setMobileMenuServicesHeight,
  mobileMenuServicesVisible,
  name,
  slug,
  subServices,
}) => {
  const hasSubServices = subServices && subServices.length > 0
  const dropdown = React.useRef(null)
  const [subServicesVisible, setSubServicesVisible] = React.useState(false)

  const updateSubServices = (subServicesVisible) => {
    const servicesDropDown = document.getElementById(
      "mobile-menu-services-dropdown"
    )
    const subServicesDropdownHeight = dropdown.current.scrollHeight

    if (subServicesVisible) {
      const hiddenHeight = mobileMenuServicesHeight - subServicesDropdownHeight

      servicesDropDown.style.maxHeight = hiddenHeight + "px"
      dropdown.current.style.maxHeight = 0
      setMobileMenuServicesHeight(hiddenHeight)
    } else {
      const revealedHeight =
        mobileMenuServicesHeight + subServicesDropdownHeight

      servicesDropDown.style.maxHeight = revealedHeight + "px"
      dropdown.current.style.maxHeight = subServicesDropdownHeight + "px"
      setMobileMenuServicesHeight(revealedHeight)
    }
    setSubServicesVisible(!subServicesVisible)
  }

  return (
    <li className="mobile-menu-link">
      {hasSubServices ? (
        <>
          <button
            className={`mobile-menu-services-item${
              subServicesVisible ? " active" : ""
            }`}
            onClick={() => updateSubServices(subServicesVisible)}
          >
            {name}
          </button>
          <ul
            className="mobile-menu-services-item-dropdown"
            ref={dropdown}
            id={slug}
          >
            {subServices.map((subService) => (
              <MobileMenuSubServicesItem
                key={subService.contentful_id}
                name={subService.name}
                serviceSlug={slug}
                slug={subService.slug}
                localServices={subService.localServices}
                subServicesVisible={subServicesVisible}
                mobileMenuServicesHeight={mobileMenuServicesHeight}
                setMobileMenuServicesHeight={setMobileMenuServicesHeight}
              />
            ))}
          </ul>
        </>
      ) : (
        <Link
          to={`/services/${slug}`}
          tabIndex={mobileMenuServicesVisible ? 0 : -1}
        >
          {name}
        </Link>
      )}
    </li>
  )
}

MobileMenuServicesItem.propTypes = {
  name: PropTypes.string.isRequired,
  mobileMenuServicesVisible: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  subServices: PropTypes.array,
}
