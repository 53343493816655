import React from "react"
import PropTypes from "prop-types"

export const ButtonWithIcon = ({ onClick, icon, children, type, iconSide }) => (
  <button
    className={`button-${type} button-with-icon icon-${iconSide}`}
    onClick={onClick}
  >
    <span className={`button-icon icon-${icon}`}></span>
    <span className="button-with-icon-text">{children}</span>
  </button>
)

ButtonWithIcon.defaultProps = {
  icon: "arrow",
  type: "primary",
  iconSide: "left",
}

ButtonWithIcon.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary"]),
  iconSide: PropTypes.oneOf(["left", "right"]),
}
