import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"

export const RichText = ({ content }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return <Img {...node.data.target} />
      },
    },
  }

  return (
    <div className="rich-text">
      {content && renderRichText(content, options)}
    </div>
  )
}

RichText.propTypes = {
  content: PropTypes.object.isRequired,
}
