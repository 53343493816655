import React from "react"
import PropTypes from "prop-types"

export const HeaderCentered = ({ heading, breakEarly }) => (
  <header
    className={`wrapper-width header header-centered${
      breakEarly ? " header-centered-break-early" : ""
    }`}
  >
    <h1 className="heading-extra-large">{heading}</h1>
  </header>
)

HeaderCentered.defaultProps = {
  breakEarly: false,
}

HeaderCentered.propTypes = {
  heading: PropTypes.string.isRequired,
  breakEarly: PropTypes.bool,
}
