import React from "react"
import PropTypes from "prop-types"

import { SubNav } from "../.."

export const CheckoutToolbar = ({ activeStep, children, totalSteps }) => (
  <SubNav additionalClasses="checkout-toolbar">
    <div className="checkout-toolbar-desktop">
      <ul className="checkout-toolbar-steps">{children}</ul>
    </div>
    <span className="checkout-toolbar-mobile">
      Step {activeStep} of {totalSteps}
    </span>
  </SubNav>
)

CheckoutToolbar.propTypes = {
  activeStep: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  totalSteps: PropTypes.number.isRequired,
}
