import React from "react"
import PropTypes from "prop-types"

export const ConfirmationPageSection = ({ children }) => (
  <section className="confirmation-page-section">{children}</section>
)

ConfirmationPageSection.propTypes = {
  children: PropTypes.node.isRequired,
}
