import React from "react"
import PropTypes from "prop-types"

export const BulletListWithIcon = ({ bullets, icon }) => (
  <ul className={`bullet-list-with-icon bullet-list-${icon}-icon`}>
    {bullets.map((bullet, index) => (
      <li key={index}>{bullet}</li>
    ))}
  </ul>
)

BulletListWithIcon.defaultProps = {
  icon: "tick",
}

BulletListWithIcon.propTypes = {
  bullets: PropTypes.array.isRequired,
  icon: PropTypes.oneOf(["tick", "warning"]),
}
