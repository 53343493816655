import React from "react"
import PropTypes from "prop-types"

import StromaCertifiedLogo from "../../../images/accreditations/Stroma-Certified-Logo.svg"
import GasSafeLogo from "../../../images/accreditations/Gas-Safe-Logo.svg"
import TrustmarkLogo from "../../../images/accreditations/Trustmark-Logo.svg"
import WACLogo from "../../../images/accreditations/Worcester-Accredited-Installer-Logo.svg"

export const Accreditations = ({ FCAIncluded }) => (
  <ul className="accreditations">
    <li>
      <StromaCertifiedLogo />
    </li>
    <li>
      <GasSafeLogo />
    </li>
    <li>
      <TrustmarkLogo />
    </li>
    <li>
      <WACLogo />
    </li>
  </ul>
)

Accreditations.defaultProps = {
  FCAIncluded: true,
}

Accreditations.propTypes = {
  FCAIncluded: PropTypes.bool,
}
