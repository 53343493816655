import React from "react"
import { Link } from "gatsby"

import HARSLogoNoIcons from "../../../../images/Home-Assist-Logo-No-Icons.svg"

export const NavMinimal = () => (
  <nav className="nav-bar nav-minimal">
    <section className="wrapper-width nav-bar-layout">
      <div className="nav-bar-left">
        <Link to="/">
          <HARSLogoNoIcons className="nav-bar-logo" />
        </Link>
      </div>
      <div className="nav-bar-right">
        <div className="nav-bar-mobile">
          <a href="tel: 0191 406 0888" className="icon-button-with-text">
            <span className="icon-phone-call"></span>
            <span className="text">Call us</span>
          </a>
        </div>
        <div className="nav-bar-desktop">
          <div className="nav-bar-desktop-call-us">
            <span className="nav-bar-desktop-call-us-text">
              {" "}
              Need help? Give us a ring
            </span>
            <span className="nav-bar-desktop-call-us-phone">0191 406 0888</span>
          </div>
        </div>
      </div>
    </section>
  </nav>
)
