import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

export const Author = ({ authorPhoto, authorPhotoDescription, authorName }) => (
  <div className="author">
    <div className="author-photo">
      <Img
        fluid={authorPhoto}
        alt={authorPhotoDescription}
        title={authorPhotoDescription}
      />
    </div>
    <span className="author-name">{authorName}</span>
  </div>
)

Author.propTypes = {
  authorPhoto: PropTypes.object.isRequired,
  authorPhotoDescription: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
}
