import React from "react"
import PropTypes from "prop-types"

export const CheckoutStep = ({
  activeStep,
  children,
  setActiveStep,
  step,
  stepDescriptionButton,
}) => {
  const { completed, description, disabled, name, number, result } = step

  const [isActive, setIsActive] = React.useState(false)
  const StepBodyElement = React.useRef(null)

  React.useEffect(() => {
    activeStep === number ? setIsActive(true) : setIsActive(false)
  }, [activeStep])

  return (
    <section
      className={`checkout-step${isActive ? " active" : ""}`}
      id={`checkout-step-${number}`}
    >
      <button
        className="checkout-step-header"
        disabled={disabled}
        onClick={() => setActiveStep(number)}
      >
        <div className="checkout-step-header-icon">
          {completed ? (
            <span className="icon-tick"></span>
          ) : (
            <span className="checkout-step-header-number">{number}</span>
          )}
        </div>
        <h2 className="checkout-step-name">
          {name}
          {result ? (
            <>
              : <span className="checkout-step-result">{result}</span>{" "}
            </>
          ) : (
            ""
          )}
        </h2>
      </button>
      <div className="checkout-step-body" ref={StepBodyElement}>
        <div className="checkout-step-description">
          {description} {stepDescriptionButton}
        </div>
        <div className="checkout-step-contents">{children}</div>
      </div>
    </section>
  )
}

CheckoutStep.defaultProps = {
  result: "",
  stepDescriptionButton: null,
}

CheckoutStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  step: PropTypes.shape({
    completed: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    result: PropTypes.string.isRequired,
  }).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  stepDescriptionButton: PropTypes.element,
}
