import React from "react"
import PropTypes from "prop-types"

import { FormPage } from "../"

export { ConfirmationPageSection } from "./ConfirmationPageSection"
export { Receipt } from "./Receipt"

export const ConfirmationPage = ({ followUp, children, heading, note }) => (
  <FormPage>
    <section className="form-wrapper form confirmation-page">
      <header className="confirmation-page-header">
        <span className="icon-tick-circle"></span>
        <h1 className="heading-large">{heading}</h1>
        <h2 className="confirmation-page-follow-up">{followUp}</h2>
        <p>{note}</p>
      </header>
      <section>{children}</section>
    </section>
  </FormPage>
)

ConfirmationPage.propTypes = {
  children: PropTypes.node.isRequired,
  followUp: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
}
