import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import { Label } from ".."

export const Select = ({ name, label, options, optional }) => {
  const [field, meta] = useField(name)

  return (
    <div
      className={`input select${
        meta.touched && meta.error ? " form-error" : ""
      }`}
    >
      <Label name={name} optional={optional}>
        {label}
      </Label>
      <select className="input-field" name={name} {...field}>
        <option value="" disabled="disabled">
          Please select...
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      <span className="form-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

Select.defaultProps = {
  optional: false,
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  optional: PropTypes.bool,
}
