import React from "react"
import { graphql, StaticQuery } from "gatsby"

import { Pill } from "../"

import GoogleLogo from "../../../images/Google_2015_logo.svg"

export const CheckatradeInfo = () => (
  <StaticQuery
    query={graphql`
      query HighLevelCheckaTradeInfo {
        reviews: contentfulCheckatradeDetails(
          contentful_id: { eq: "2QBHibRnGkaXnxPHp4sucH" }
        ) {
          contentful_id
          reviewCount
          reviewRating
          featuredReviews {
            summary {
              summary
            }
            title
            reviewDate
            reviewerLocation
            id
          }
        }
      }
    `}
    render={(data) => (
      <div className="checkatrade-info">
        <Pill text="15+ Years in operation" />
        <GoogleLogo className="checkatrade-info-logo" />
        <span className="checkatrade-info-score">
          {data.reviews.reviewRating}/5
        </span>
        <span className="checkatrade-info-link">
          <span className="icon-link-external"></span>
          <a
            className="checkatrade-info-link-text"
            href="https://www.google.com/maps/place/HomeAssist.services/@54.9895021,-1.607932,17z/data=!3m1!5s0x487e70d98949efd3:0x1a31560bb1a59a76!4m7!3m6!1s0x487e70d98bfed04b:0x675bb7e509da7f3d!8m2!3d54.9895021!4d-1.6053517!9m1!1b1"
            target="_blank"
            rel="noreferrer"
          >
            {data.reviews.reviewCount}+ Reviews
          </a>
        </span>
      </div>
    )}
  />
)
