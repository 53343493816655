import React from "react"
import PropTypes from "prop-types"

export const Receipt = ({ rows }) => (
  <ul className="receipt">
    {rows.map((row, index) => (
      <li key={index} className="receipt-row">
        {row}
      </li>
    ))}
  </ul>
)

Receipt.propTypes = {
  rows: PropTypes.array.isRequired,
}
