import React from "react"
import PropTypes from "prop-types"

export const Pill = ({ text, colour }) => (
  <span className={`pill ${colour}`}>{text}</span>
)

Pill.defaultProps = {
  colour: "yellow",
}

Pill.propTypes = {
  colour: PropTypes.string,
  text: PropTypes.string.isRequired,
}
