import React from "react"
import PropTypes from "prop-types"
import SVG from "react-inlinesvg"

export const IllustratedOption = ({
  children,
  heading,
  illustration,
  number,
  type,
}) => {
  return (
    <div className="illustrated-option">
      <div className="illustrated-option-graphic">
        {type === "contentful-svg" ? (
          <SVG src={illustration} />
        ) : (
          illustration // inline-svg through react import svg
        )}
        {number !== 0 && (
          <span className="illustrated-option-count">
            <span className="illustrated-option-count-number">{number}</span>
          </span>
        )}
      </div>
      <h3 className="heading-small">{heading}</h3>
      {children}
    </div>
  )
}

IllustratedOption.defaultProps = {
  number: 0,
  type: "inline-svg",
}

IllustratedOption.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  illustration: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  number: PropTypes.number,
}
