import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { NavSubServicesItem } from "./NavSubServicesItem"

export const NavServicesItem = ({
  name,
  navBarServicesVisible,
  slug,
  subServices,
}) => {
  const [subServicesVisible, setSubServicesVisible] = React.useState(false)

  React.useEffect(() => {
    if (!navBarServicesVisible) {
      setSubServicesVisible(false)
    }

    return () => {
      setSubServicesVisible(false)
    }
  }, [navBarServicesVisible])
  return (
    <li
      className={`nav-bar-link${
        subServices && subServices.length > 0 ? " nav-bar-link-chevron" : ""
      }`}
      onMouseOver={() => setSubServicesVisible(true)}
      onMouseLeave={() => setSubServicesVisible(false)}
    >
      <Link to={`/services/${slug}`} tabIndex={navBarServicesVisible ? 0 : -1}>
        {name}
      </Link>
      <span className="nav-bar-services-dropdown-link-background"></span>
      {subServices && subServices.length > 0 && (
        <ul
          className={`nav-item-sub-services${
            subServicesVisible ? " active" : ""
          }`}
        >
          {subServices.map((subService) => (
            <NavSubServicesItem
              key={subService.contentful_id}
              name={subService.name}
              serviceSlug={slug}
              slug={subService.slug}
              localServices={subService.localServices}
              subServicesVisible={subServicesVisible}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

NavServicesItem.defaultProps = {
  subServices: [],
}

NavServicesItem.propTypes = {
  name: PropTypes.string.isRequired,
  navBarServicesVisible: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  subServices: PropTypes.array,
}
