import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import BlobTall from "../../../../images/Blob-tall.svg"

export const HeaderBlob = ({ children, image, imageDescription }) => (
  <header className="wrapper-width grid-col-2 header">
    <div className="header-right header-blob">
      <BlobTall />
      <Img
        className="header-blob-image"
        fluid={image}
        alt={imageDescription}
        title={imageDescription}
      />
    </div>
    <div className="header-left">{children}</div>
  </header>
)

HeaderBlob.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.object.isRequired,
  imageDescription: PropTypes.string.isRequired,
}
