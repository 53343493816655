import React from "react"
import PropTypes from "prop-types"

export const Card = ({ children, heading, preHeading }) => (
  <div className="card">
    <header className="card-header">
      <span className="card-pre-heading">{preHeading}</span>
      <h3 className="heading-large">{heading}</h3>
    </header>
    <div className="card-body">{children}</div>
  </div>
)

Card.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  preHeading: PropTypes.string.isRequired,
}
