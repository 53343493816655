import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import {
  ConfirmationPage,
  ConfirmationPageSection,
  Receipt,
  DataDisplay,
} from "../../"

import BlobWide from "../../../../images/Blob-wide.svg"
import RepairsIllustration from "../../../../images/illustrations/services/repairs.svg"

export const RequestACallbackConfirmation = ({ callbackData }) => {
  const convertHypensToSpaces = (text) => {
    text = text.replace("-", " ")
    return text
  }

  return (
    <ConfirmationPage
      heading="Callback requested"
      followUp="We’ll ring you within 1-2 working days"
      note="Thank you for choosing Home Assist Repairs and Servicing"
    >
      <Helmet title="Callback Requested | Home Assist Repairs and Servicing" />
      <ConfirmationPageSection>
        <div>
          <div className="callback-request-confirmation-illustration">
            <BlobWide />
            <RepairsIllustration />
          </div>
        </div>
        <div>
          <h2 className="heading-small">
            Callback Request #{callbackData.generalCallbackRequestNumber}
          </h2>
          <div className="callback-request-confirmation-data-group">
            <b>Services required</b>
            <Receipt rows={callbackData.servicesRequired} />
          </div>
          <div className="callback-request-confirmation-data-group">
            <b>How can we help?</b>
            <Receipt rows={[callbackData.howWeCanHelp]} />
          </div>
          <div className="callback-request-confirmation-data-group">
            <b>How urgent is this work?</b>
            <Receipt rows={[convertHypensToSpaces(callbackData.urgency)]} />
          </div>
        </div>
      </ConfirmationPageSection>
      <ConfirmationPageSection>
        <DataDisplay label="Contact">
          {callbackData.firstName && callbackData.lastName && (
            <>
              {callbackData.firstName + " " + callbackData.lastName}
              <br />
            </>
          )}
          {callbackData.email && (
            <>
              {callbackData.email}
              <br />
            </>
          )}
          {callbackData.mobile && (
            <>
              {callbackData.mobile}
              <br />
            </>
          )}
          {callbackData.homePhone && (
            <>
              {callbackData.homePhone}
              <br />
            </>
          )}
        </DataDisplay>
        <DataDisplay label="Is there a good time for us to call?">
          {callbackData.goodTimeToCall}
        </DataDisplay>
      </ConfirmationPageSection>

      <ConfirmationPageSection>
        <DataDisplay label="Property">
          {callbackData.address ? (
            <>
              {callbackData.address.line1 && (
                <>
                  {callbackData.address.line1},<br />
                </>
              )}
              {callbackData.address.line2 && (
                <>
                  {callbackData.address.line2},<br />
                </>
              )}
              {callbackData.address.city && (
                <>
                  {callbackData.address.city},<br />
                </>
              )}
              {callbackData.address.region && (
                <>
                  {callbackData.address.region},<br />
                </>
              )}
              {callbackData.address.postalCode &&
                callbackData.address.postalCode}
            </>
          ) : (
            "No address found"
          )}
        </DataDisplay>
      </ConfirmationPageSection>
    </ConfirmationPage>
  )
}

RequestACallbackConfirmation.propTypes = {
  callbackData: PropTypes.object.isRequired,
}
