import React from "react"
import PropTypes from "prop-types"

import { IconButton } from "../"

export const Modal = ({
  children,
  dismissFunc,
  heading,
  isVisible,
  additionalClasses,
  size,
}) => {
  React.useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [isVisible])

  React.useEffect(() => {
    return () => {
      document.body.style.overflow = "visible"
    }
  }, [])
  return (
    <>
      <div
        className={`modal${isVisible ? " active" : ""}${
          size === "medium" ? " modal-medium" : ""
        }${additionalClasses ? ` ${additionalClasses}` : ""}`}
      >
        <header className="modal-header">
          <span className="heading-medium">{heading}</span>
          <IconButton
            func={dismissFunc}
            name="Close modal"
            icon="cross"
            tabIndex={isVisible ? 0 : -1}
          />
        </header>
        <div className="modal-body">{children}</div>
      </div>
      <div
        className={`overlay overlay-modal${isVisible ? " active" : ""}`}
        onClick={dismissFunc}
      ></div>
    </>
  )
}

Modal.defaultProps = {
  size: "small",
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  dismissFunc: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  size: PropTypes.string,
}
