import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import HARSLogoNoIcons from "../../../../images/Home-Assist-Logo-No-Icons.svg"

export const NavCheckout = ({
  setBreakdownVisibleMobile,
  breakdownIcon,
  breakdownText,
}) => (
  <nav className="nav-bar nav-minimal">
    <section className="wrapper-width nav-bar-layout">
      <div className="nav-bar-left">
        <Link to="/">
          <HARSLogoNoIcons className="nav-bar-logo" />
        </Link>
      </div>
      <div className="nav-bar-right">
        <div className="nav-bar-mobile">
          <button
            className="icon-button-with-text"
            onClick={setBreakdownVisibleMobile}
          >
            <span className={`icon-${breakdownIcon}`}></span>
            <span className="text">{breakdownText}</span>
          </button>
        </div>
        <div className="nav-bar-desktop">
          <div className="nav-bar-desktop-call-us">
            <span className="nav-bar-desktop-call-us-text">
              {" "}
              Need help? Give us a ring
            </span>
            <span className="nav-bar-desktop-call-us-phone">0191 406 0888</span>
          </div>
        </div>
      </div>
    </section>
  </nav>
)

NavCheckout.propTypes = {
  setBreakdownVisibleMobile: PropTypes.func.isRequired,
  breakdownIcon: PropTypes.oneOf(["order", "shield"]).isRequired,
  breakdownText: PropTypes.string.isRequired,
}
