import React from "react"
import PropTypes from "prop-types"

export const ButtonWithLoaderPayment = ({ children, isLoading, ...props }) => (
  <button
    {...props}
    className={`button-primary button-with-loader-payment button-submit${
      isLoading ? " loading" : ""
    }`}
  >
    <span className="button-with-loader-text">{children}</span>
  </button>
)

ButtonWithLoaderPayment.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
