import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

export const MobileMenuSubServicesItem = ({
  name,
  serviceSlug,
  slug,
  localServices,
  subServicesVisible,
  setMobileMenuServicesHeight,
}) => {
  const hasLocalServices = localServices && localServices.length > 0
  const [localServicesVisible, setLocalServicesVisible] = React.useState(false)
  const localServiceDropdown = React.useRef(null)

  const updateLocalServices = () => {
    const servicesDropDown = document.getElementById(
      "mobile-menu-services-dropdown"
    )
    const subServiceDropdown = document.getElementById(serviceSlug)

    const servicesDropDownHeight = servicesDropDown.scrollHeight
    const subServicesDropdownHeight = subServiceDropdown.scrollHeight
    const localServicesDropdownHeight =
      localServiceDropdown.current.scrollHeight

    if (localServicesVisible) {
      // Hide localServices
      const revealedHeight = servicesDropDownHeight - subServicesDropdownHeight

      localServiceDropdown.current.style.maxHeight = 0
      subServiceDropdown.style.maxHeight = subServicesDropdownHeight + "px"
      servicesDropDown.style.maxHeight = revealedHeight + "px"

      setMobileMenuServicesHeight(revealedHeight)
    } else {
      // Make localServices visible
      const localServicesHeight = localServicesDropdownHeight

      const subServiceHeight =
        subServicesDropdownHeight + localServicesDropdownHeight

      const totalHeight =
        servicesDropDownHeight +
        subServicesDropdownHeight +
        localServicesDropdownHeight

      localServiceDropdown.current.style.maxHeight = localServicesHeight + "px"
      subServiceDropdown.style.maxHeight = subServiceHeight + "px"
      servicesDropDown.style.maxHeight = totalHeight + "px"

      setMobileMenuServicesHeight(totalHeight)
    }
    setLocalServicesVisible((localServicesVisible) => !localServicesVisible)
  }

  return (
    <li className="mobile-menu-link">
      {hasLocalServices ? (
        <>
          <button
            className={`mobile-menu-services-item${
              localServicesVisible ? " active" : ""
            }`}
            onClick={() => updateLocalServices()}
          >
            {name}
          </button>
          <ul
            className="mobile-menu-services-item-dropdown"
            ref={localServiceDropdown}
          >
            {localServices.map((localService) => (
              <Link
                key={localService.contentful_id}
                to={`/services/${serviceSlug}/${slug}/${localService.slug}`}
                tabIndex={localServicesVisible ? 0 : -1}
              >
                {localService.name}
              </Link>
            ))}
          </ul>
        </>
      ) : (
        <Link
          to={`/services/${serviceSlug}/${slug}`}
          tabIndex={subServicesVisible ? 0 : -1}
        >
          {name}
        </Link>
      )}
    </li>
  )
}

MobileMenuSubServicesItem.propTypes = {
  name: PropTypes.string.isRequired,
  serviceSlug: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  localServices: PropTypes.arrayOf(
    PropTypes.shape({
      contentful_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ),
  subServicesVisible: PropTypes.bool.isRequired,
  mobileMenuServicesHeight: PropTypes.number.isRequired,
  setMobileMenuServicesHeight: PropTypes.func.isRequired,
}
