import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

export const ButtonGroup = ({ name, label, options }) => {
  const [field, meta, helpers] = useField(name)
  const { setValue } = helpers

  return (
    <div
      className={`form-element-full-width button-group${
        meta.touched && meta.error ? " form-error" : ""
      }`}
    >
      <input type="hidden" name={name} {...field} />
      <label className="label">{label}</label>
      <div>
        {options.map((option) => (
          <span className="button-group-option" key={option.id}>
            <button
              className={`button-group-option-button${
                field.value === option.value ? " active" : ""
              }`}
              type="button"
              onClick={() => setValue(option.value)}
              onKeyPress={(e) =>
                e.key === " " || e.key === "Spacebar"
                  ? setValue(option.value)
                  : null
              }
            >
              {option.label}
            </button>
          </span>
        ))}
      </div>
      <span className="form-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

ButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
