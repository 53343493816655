import React from "react"
import PropTypes from "prop-types"

export const TagFilterButton = ({ active, tag, ...props }) => {
  return (
    <button
      className={`tag-filter-button${active ? " active" : ""}`}
      {...props}
    >
      {tag}
    </button>
  )
}

TagFilterButton.propTypes = {
  active: PropTypes.bool.isRequired,
  tag: PropTypes.string.isRequired,
}
