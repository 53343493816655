import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

import { IconButton, IconButtonWithText } from "../../"
import { useOnClickOutside } from "../../../../hooks"
import { NavServicesItem } from "./NavServicesItem"
import { MobileMenuServicesItem } from "./MobileMenuServicesItem"

import HARSLogoNoIcons from "../../../../images/Home-Assist-Logo-No-Icons.svg"

export const NavMain = () => {
  const [mobileMenuVisible, setMobileMenuVisibility] = React.useState(false)
  const [
    mobileMenuServicesHeight,
    setMobileMenuServicesHeight,
  ] = React.useState(0)
  const [
    mobileMenuServicesVisible,
    setMobileMenuServicesVisibility,
  ] = React.useState(false)
  const [navBarServicesVisible, setNavBarServicesVisibility] = React.useState(
    false
  )

  const updateMobileServicesDropdown = (servicesVisible) => {
    const servicesDropDown = document.getElementById(
      "mobile-menu-services-dropdown"
    )

    if (servicesVisible) {
      servicesDropDown.style.maxHeight = null
      setMobileMenuServicesHeight(0)
    } else {
      servicesDropDown.style.maxHeight = servicesDropDown.scrollHeight + "px"
      setMobileMenuServicesHeight(servicesDropDown.scrollHeight)
    }

    setMobileMenuServicesVisibility(!servicesVisible)
  }

  const ref = React.useRef()

  useOnClickOutside(ref, () => setNavBarServicesVisibility(false))

  return (
    <StaticQuery
      query={graphql`
        query allBoilerServicesNav {
          services: allContentfulServicePages(
            sort: { fields: name, order: ASC }
          ) {
            edges {
              node {
                contentful_id
                name
                slug
                subServices {
                  contentful_id
                  slug
                  name
                  localServices {
                    contentful_id
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <section className="nav">
            <div className="banner">
              <p className="message wrapper-width">
                Keeping the North of England safe and warm this winter
              </p>
            </div>
            <nav className="nav-bar">
              <section className="wrapper-width nav-bar-layout">
                <div className="nav-bar-left">
                  <Link to="/">
                    <HARSLogoNoIcons />
                  </Link>
                  <ul className="nav-bar-links">
                    <li
                      ref={ref}
                      className={`nav-bar-services-wrapper${
                        navBarServicesVisible ? " active" : ""
                      }`}
                    >
                      <button
                        className="nav-bar-link nav-bar-services-button"
                        onClick={() =>
                          setNavBarServicesVisibility(
                            (navBarServicesVisible) => !navBarServicesVisible
                          )
                        }
                      >
                        <span>Our services</span>{" "}
                        <span className="icon-chevron-down"></span>
                      </button>
                      <ul
                        className="nav-bar-services-dropdown"
                        id="nav-bar-services-dropdown"
                      >
                        {data.services.edges.map(({ node }) => (
                          <NavServicesItem
                            key={node.contentful_id}
                            name={node.name}
                            navBarServicesVisible={navBarServicesVisible}
                            slug={node.slug}
                            subServices={node.subServices}
                          />
                        ))}
                      </ul>
                    </li>
                    <li className="nav-bar-link">
                      <Link to="/new-boilers">New boilers</Link>
                    </li>
                    <li className="nav-bar-link">
                      <Link to="/home-cover-plans">Home cover plans</Link>
                    </li>
                    <li className="nav-bar-link">
                      <Link to="/why-us">Why us?</Link>
                    </li>
                  </ul>
                </div>
                <div className="nav-bar-right">
                  <div className="nav-bar-desktop">
                    <span className="nav-bar-phone">
                      <span className="icon-phone-call"></span>
                      <span className="nav-bar-phone-number">
                        0191 406 0888
                      </span>
                    </span>
                    <Link to="/quote" className="button-primary">
                      Get a new boiler
                    </Link>
                  </div>
                  <div className="nav-bar-mobile">
                    <a
                      href="tel: 0191 406 0888"
                      className="icon-button-with-text nav-bar-mobile-call-us"
                    >
                      <span className="icon-phone-call"></span>
                      <span className="text">Call us</span>
                    </a>
                    <IconButtonWithText
                      func={() =>
                        setMobileMenuVisibility(
                          (mobileMenuVisible) => !mobileMenuVisible
                        )
                      }
                      icon="menu"
                      text="Menu"
                    />
                  </div>
                </div>
              </section>
            </nav>
          </section>
          <section
            className={`mobile-menu${mobileMenuVisible ? " active" : ""}`}
          >
            <div className="mobile-menu-header">
              <Link to="/" tabIndex={mobileMenuVisible ? 0 : -1}>
                <HARSLogoNoIcons />
              </Link>
              <IconButton
                tabIndex={mobileMenuVisible ? 0 : -1}
                name="Close mobile menu"
                func={() => setMobileMenuVisibility(false)}
              />
            </div>
            <div className="mobile-menu-body">
              <Link
                to="/quote"
                className="button-primary"
                tabIndex={mobileMenuVisible ? 0 : -1}
              >
                Get a new boiler
              </Link>
              <ul className="mobile-menu-links">
                <li
                  className={`mobile-menu-link mobile-menu-services-wrapper${
                    mobileMenuServicesVisible ? " active" : ""
                  }`}
                >
                  <button
                    className="mobile-menu-services-button"
                    onClick={() =>
                      updateMobileServicesDropdown(mobileMenuServicesVisible)
                    }
                    tabIndex={mobileMenuVisible ? 0 : -1}
                  >
                    <span>Our services</span>{" "}
                    <span
                      className={
                        mobileMenuServicesVisible ? "icon-minus" : "icon-plus"
                      }
                    ></span>
                  </button>
                  <ul
                    className="mobile-menu-services-dropdown"
                    id="mobile-menu-services-dropdown"
                  >
                    {data.services.edges.map(({ node }) => (
                      <MobileMenuServicesItem
                        key={node.contentful_id}
                        mobileMenuServicesHeight={mobileMenuServicesHeight}
                        mobileMenuServicesVisible={mobileMenuServicesVisible}
                        name={node.name}
                        setMobileMenuServicesHeight={
                          setMobileMenuServicesHeight
                        }
                        slug={node.slug}
                        subServices={node.subServices}
                      />
                    ))}
                  </ul>
                </li>
                <li className="mobile-menu-link">
                  <Link to="/new-boilers" tabIndex={mobileMenuVisible ? 0 : -1}>
                    New boilers
                  </Link>
                </li>
                <li className="mobile-menu-link">
                  <Link
                    to="/home-cover-plans"
                    tabIndex={mobileMenuVisible ? 0 : -1}
                  >
                    Home cover plans
                  </Link>
                </li>
                <li className="mobile-menu-link">
                  <Link to="/why-us" tabIndex={mobileMenuVisible ? 0 : -1}>
                    Why us?
                  </Link>
                </li>
              </ul>
            </div>
          </section>
          <div
            aria-label="Close mobile menu"
            className={`overlay${mobileMenuVisible ? " active" : ""}`}
            onClick={() => setMobileMenuVisibility(false)}
            role="button"
          ></div>
        </>
      )}
    />
  )
}
