import React from "react"
import PropTypes from "prop-types"

import LoaderSVG from "../../../../images/loader.svg"

export const Loader = ({ text, error, errorText, errorButton }) => (
  <div className="loader">
    <section className="loader-content">
      {error ? (
        <>
          <span className="icon-warning"></span>
          <h1 className="heading-medium">{errorText}</h1>
          {errorButton && errorButton}
        </>
      ) : (
        <>
          <LoaderSVG />
          <h1 className="heading-medium">{text}</h1>
        </>
      )}
    </section>
  </div>
)

Loader.defaultProps = {
  errorText: "",
  errorButton: null,
}

Loader.propTypes = {
  text: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  errorButton: PropTypes.node,
}
