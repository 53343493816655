import React from "react"
import PropTypes from "prop-types"

export const IconButtonWithText = ({ icon, text, func }) => (
  <button className="icon-button-with-text" onClick={func}>
    <span className={`icon-${icon}`}></span>
    <span className="text">{text}</span>
  </button>
)

IconButtonWithText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  func: PropTypes.func.isRequired,
}
