import React from "react"
import axios from "axios"
import { Formik } from "formik"
import * as Yup from "yup"
import { useSnackbar } from "react-simple-snackbar"

import {
  ButtonWithLoader,
  Form,
  Input,
  Select,
  SnackBarOptions,
  TextArea,
} from "../"
import { defaultRequiredText } from "../../../constants"

export const ComplaintsForm = () => {
  const [openSnackbar] = useSnackbar(SnackBarOptions)

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.GATSBY_API_BASE_URL}/complaint/send`,
        data: values,
      })
      resetForm({})
      openSnackbar(
        "Complaint submitted, we'll contact you within 1-2 working days"
      )
    } catch (error) {
      console.log(error)
      openSnackbar("Could not submit complaint, please try again")
    }
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        topic: "",
        complaint: "",
        name: "",
        email: "",
        phone: "",
      }}
      validationSchema={Yup.object().shape({
        topic: Yup.string().required("Please choose a complaint topic"),
        complaint: Yup.string().required("Please tell us about your complaint"),
        name: Yup.string().required("Please tell us your name"),
        email: Yup.string()
          .email("Please enter a valid email address")
          .required("Please tell us your email"),
        phone: Yup.string(),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Select
            name="topic"
            label="Topic"
            options={[
              {
                id: "1",
                value: "Billing",
                name: "Billing",
              },
              {
                id: "2",
                value: "Customer Service",
                name: "Customer Service",
              },
              {
                id: "3",
                value: "Work Quality",
                name: "Work Quality",
              },
            ]}
          />
          <TextArea name="complaint" label="Complaint" />
          <Input name="name" label="Your name" />
          <Input name="email" label="Email" />
          <Input name="phone" label="Phone" optional={true} />
          <ButtonWithLoader
            disabled={isSubmitting}
            additionalClasses="button-submit"
            type="submit"
          >
            Submit complaint
          </ButtonWithLoader>
        </Form>
      )}
    </Formik>
  )
}
