import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import BlobTall from "../../../images/Blob-tall.svg"

export const ImageSection = ({
  backgroundColour,
  blobColour,
  children,
  direction,
  image,
  imageDescription,
}) => {
  return (
    <section className={`background-${backgroundColour}`}>
      <section
        className={`wrapper-width wrapper-height grid-col-2 image-section direction-${direction}`}
      >
        <div className="image-section-image-blob">
          <BlobTall className={blobColour} />
          <Img
            className="image-section-image"
            fluid={image}
            alt={imageDescription}
            title={imageDescription}
          />
        </div>
        <div className="image-section-content">{children}</div>
      </section>
    </section>
  )
}

ImageSection.defaultProps = {
  backgroundColour: "white",
  blobColour: "green",
  direction: "text-left",
}

ImageSection.propTypes = {
  backgroundColour: PropTypes.oneOf(["white", "grey-lightest"]),
  blobColour: PropTypes.oneOf(["green", "blue", "yellow"]),
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(["text-left", "text-right"]),
  image: PropTypes.object.isRequired,
  imageDescription: PropTypes.string.isRequired,
}
