import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import { Label } from ".."

export const Input = ({ label, optional, name, type, placeholder }) => {
  const [field, meta] = useField(name)

  return (
    <div className={`input${meta.touched && meta.error ? " form-error" : ""}`}>
      <Label name={name} optional={optional}>
        {label}
      </Label>
      <input
        className="input-field"
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        {...field}
      />
      <span className="form-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

Input.defaultProps = {
  optional: false,
  placeholder: "",
  type: "text",
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}
