import React from "react"
import PropTypes from "prop-types"

export const CheckboxGroup = ({ children }) => (
  <div className="form-element-full-width checkbox-group">{children}</div>
)

CheckboxGroup.propTypes = {
  children: PropTypes.node.isRequired,
}
