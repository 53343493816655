import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { BreakDownTable, IconButton } from "../.."

import HARSLogoNoIcons from "../../../../images/Home-Assist-Logo-No-Icons.svg"

export const CheckoutBreakdown = ({
  changeLinkUrl,
  children,
  dismissFunc,
  heading,
  isVisibleMobile,
  rows,
  total,
  useStrings,
}) => {
  return (
    <>
      <aside
        className={`checkout-breakdown${isVisibleMobile ? " active" : ""}`}
      >
        <header className="checkout-breakdown-header">
          <HARSLogoNoIcons />
          <IconButton name="Close checkout breakdown" func={dismissFunc} />
        </header>
        <section className="checkout-breakdown-body">
          <div className="checkout-breakdown-body-header">
            <h1 className="checkout-breakdown-heading">{heading}</h1>
            <Link className="checkout-breakdown-change-link" to={changeLinkUrl}>
              Change
            </Link>
          </div>
          <BreakDownTable
            rows={rows}
            returnFree={true}
            total={total}
            useStrings={useStrings}
          />
          {children}
        </section>
      </aside>
      <div
        aria-label="Close mobile menu"
        className={`overlay${isVisibleMobile ? " active" : ""}`}
        onClick={dismissFunc}
        role="button"
      ></div>
    </>
  )
}

CheckoutBreakdown.defaultProps = {
  children: "",
  useStrings: false,
}

CheckoutBreakdown.propTypes = {
  isVisibleMobile: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  changeLinkUrl: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired
  ).isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  dismissFunc: PropTypes.func.isRequired,
  useStrings: PropTypes.bool,
}
