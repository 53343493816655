import React from "react"
import { Link } from "gatsby"

import { Accreditations } from "../.."

export const FooterMinimal = () => (
  <div className="footer-minimal">
    <span className="copyright">
      &copy; {new Date().getFullYear()} HomeAssist.Services Ltd
    </span>
    <ul className="legal-pages">
      <li>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </li>
      <li>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </li>
    </ul>
    <Accreditations />
    <span>
      HomeAssist.Services Ltd registered in England and Wales, Company No. 14329915. Registered office 3 Bevan Court, Morpeth, Northumberland, NE61 6FH. HomeAssist.Services Ltd Trademarks and brands are the property of their respective owners. All finance is subject to status and income. Applicants must be 18 or over, terms and conditions apply, guarantees and indemnities may be required.
    </span>
  </div>
)
