import React from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"

export const Faq = ({ question, children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const FaqElement = React.useRef(null)

  const toggleFaq = () => {
    const FaqBody = FaqElement.current
    const FaqBodyHeight = FaqBody.scrollHeight

    if (FaqBody.style.maxHeight) {
      FaqBody.style.maxHeight = null
      setIsOpen(false)
    } else {
      FaqBody.style.maxHeight = FaqBodyHeight + "px"
      setIsOpen(true)
    }
  }

  return (
    <section className={`faq${isOpen ? " active" : ""}`}>
      <button className="faq-header" onClick={() => toggleFaq()}>
        <h3 className="faq-question">{question}</h3>
      </button>
      <div className="faq-body" ref={FaqElement}>
        <ReactMarkdown source={children} />
      </div>
    </section>
  )
}

Faq.propTypes = {
  children: PropTypes.node.isRequired,
  question: PropTypes.string.isRequired,
}
