import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

export const BlogArticleStandard = ({
  title,
  featuredText,
  featuredImage,
  featuredImageDescription,
  slug,
}) => (
  <Link to={slug} className="blog-article">
    <Img
      className="blog-article-photo"
      fluid={featuredImage}
      alt={featuredImageDescription}
      title={featuredImageDescription}
    />
    <div className="blog-article-body">
      <h3 className="heading-small">{title}</h3>
      <p>{featuredText}</p>
      <span className="link-with-icon">
        <span className="icon-arrow-right" />
        <span className="link-with-icon-text">Find out more</span>
      </span>
    </div>
  </Link>
)

BlogArticleStandard.propTypes = {
  title: PropTypes.string.isRequired,
  featuredText: PropTypes.string.isRequired,
  featuredImage: PropTypes.object.isRequired,
  featuredImageDescription: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired,
}
