import React from "react"
import PropTypes from "prop-types"

export const CheckoutToolbarItem = ({
  activeStep,
  setActiveStep,
  step,
  chevron,
}) => {
  const { name, number, disabled } = step

  return (
    <li
      className={`checkout-toolbar-step${
        number === activeStep ? " active" : ""
      }`}
    >
      <button disabled={disabled} onClick={() => setActiveStep(number)}>
        {name}
      </button>
      {chevron && <span className="icon-chevron-right"></span>}
    </li>
  )
}

CheckoutToolbarItem.defaultProps = {
  chevron: true,
}

CheckoutToolbarItem.propTypes = {
  activeStep: PropTypes.number.isRequired,
  step: PropTypes.shape({
    name: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
  }).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  chevron: PropTypes.bool,
}
