import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import { Tag, Author } from ".."

export const BlogArticleFeatured = ({
  title,
  featuredText,
  featuredImage,
  featuredImageDescription,
  tags,
  author,
  slug,
}) => (
  <Link to={`${slug}`} className="blog-article blog-article-featured">
    <Img
      className="blog-article-photo"
      fluid={featuredImage}
      alt={featuredImageDescription}
      title={featuredImageDescription}
    />
    <div className="blog-article-body">
      <h3 className="heading-small">{title}</h3>
      <p>{featuredText}</p>
      <div>
        {tags.map((tag) => (
          <Tag key={tag.id} tag={tag.tag} />
        ))}
      </div>
      <Author
        authorName={author.authorName}
        authorPhoto={author.authorImage.fluid}
        authorPhotoDescription={author.authorImage.title}
      />
    </div>
  </Link>
)

BlogArticleFeatured.propTypes = {
  title: PropTypes.string.isRequired,
  featuredText: PropTypes.string.isRequired,
  featuredImage: PropTypes.object.isRequired,
  featuredImageDescription: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  author: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
}
