import React from "react"
import PropTypes from "prop-types"

import { Nav } from "../"
import { Footer } from "../"

export const Layout = ({ children, minimal }) => {
  // Needed incase wrapper div isn't full height of screen
  React.useEffect(() => {
    // Making background light grey on mount
    if (minimal) {
      document.body.classList.add("background-grey-lightest")
    }
    return () => {
      // Resetting background colour on unmount so colour doesn't persist on future pages
      if (minimal) {
        document.body.classList.remove("background-grey-lightest")
      }
    }
  }, [])
  return (
    <div className={minimal ? "background-grey-lightest" : ""}>
      <Nav minimal={minimal} />
      {children}
      <Footer minimal={minimal} />
    </div>
  )
}

Layout.defaultProps = {
  minimal: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  minimal: PropTypes.bool,
}
