import React from "react"
import PropTypes from "prop-types"

export const HeaderCenteredIntroText = ({ children, breakEarly }) => (
  <div
    className={`wrapper-width header-centered-intro-text${
      breakEarly ? " header-centered-intro-text-break-early" : ""
    }`}
  >
    {children}
  </div>
)

HeaderCenteredIntroText.defaultProps = {
  breakEarly: false,
}

HeaderCenteredIntroText.propTypes = {
  children: PropTypes.node.isRequired,
  breakEarly: PropTypes.bool,
}
