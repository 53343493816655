import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

export const NavSubServicesItem = ({
  name,
  serviceSlug,
  slug,
  localServices,
  subServicesVisible,
}) => {
  const [localServicesVisible, setLocalServicesVisible] = React.useState(false)

  return (
    <li
      className={`nav-bar-link${
        localServices && localServices.length > 0 ? " nav-bar-link-chevron" : ""
      }`}
      onMouseOver={() => setLocalServicesVisible(true)}
      onMouseLeave={() => setLocalServicesVisible(false)}
    >
      <Link
        to={`/services/${serviceSlug}/${slug}`}
        tabIndex={subServicesVisible ? 0 : -1}
      >
        {name}
      </Link>
      <span className="nav-bar-services-dropdown-link-background"></span>
      {localServices && localServices.length > 0 && (
        <ul
          className={`nav-item-sub-services${
            localServicesVisible ? " active" : ""
          }`}
        >
          {localServices.map((localService) => (
            <li className="nav-bar-link" key={localService.contentful_id}>
              <Link
                to={`/services/${serviceSlug}/${slug}/${localService.slug}`}
                tabIndex={localServicesVisible ? 0 : -1}
              >
                {localService.name}
              </Link>
              <span className="nav-bar-services-dropdown-link-background"></span>
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

NavSubServicesItem.propTypes = {
  name: PropTypes.string.isRequired,
  serviceSlug: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  localServices: PropTypes.arrayOf(
    PropTypes.shape({
      contentful_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ),
  subServicesVisible: PropTypes.bool.isRequired,
}
