import React from "react"
import PropTypes from "prop-types"

export const SubNav = ({ children, additionalClasses }) => {
  return (
    <div
      className={`sub-nav${additionalClasses ? ` ${additionalClasses}` : ""}`}
    >
      {children}
    </div>
  )
}

SubNav.defaultProps = {
  additionalClasses: "",
}

SubNav.propTypes = {
  children: PropTypes.node.isRequired,
}
