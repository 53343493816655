import React from "react"
import PropTypes from "prop-types"

export const FormSection = ({ children }) => {
  return <div className="form-section">{children}</div>
}

FormSection.propTypes = {
  children: PropTypes.node.isRequired,
}
