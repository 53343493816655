import React from "react"
import PropTypes from "prop-types"

export const FormPage = ({ children }) => (
  <main className="wrapper-width form-page">{children}</main>
)

FormPage.propTypes = {
  children: PropTypes.node.isRequired,
}
