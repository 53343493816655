import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import { FooterMinimal } from "../FooterMinimal"

import HARSLogo from "../../../../images/Home-Assist-Logo.svg"

export const FooterMain = () => (
  <StaticQuery
    query={graphql`
      query allBoilerServicesFooter {
        allContentfulServicePages(sort: { fields: name, order: ASC }) {
          edges {
            node {
              contentful_id
              name
              slug
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        <div className="footer-main">
          <HARSLogo className="footer-logo" />
          <ul className="link-group">
            <li className="link-group-title">Services</li>
            {data.allContentfulServicePages.edges.map(({ node }) => (
              <li className="link-group-link" key={node.contentful_id}>
                <Link to={`/services/${node.slug}`}>{node.name}</Link>
              </li>
            ))}
          </ul>
          <ul className="link-group">
            <li className="link-group-title">Company</li>
            <li className="link-group-link">
              <Link to="/why-us">Why us?</Link>
            </li>
            <li className="link-group-link">
              <Link to="/contact-us">Contact us</Link>
            </li>
            <li className="link-group-link">
              <Link to="/blog">Blog</Link>
            </li>
            <li className="link-group-link">
              <Link to="/covid-response">COVID Response</Link>
            </li>
            <li className="link-group-link">
              <Link to="/complaints">Complaints</Link>
            </li>
          </ul>
          <ul className="link-group social-icons">
            <li className="link-group-title">Follow us</li>
            <li>
              <a
                href="https://www.facebook.com/homeassistrepairsandservicingltd/"
                className="social-icon-button icon-facebook"
                target="_blank"
                rel="noreferrer"
                aria-label="Home Assist Repairs and Servicing Facebook page"
              >
                <span className="hidden">
                  Home Assist Repairs and Servicing Facebook page
                </span>
              </a>
              <a
                href="https://www.instagram.com/homeassistrepairsservicing/"
                className="social-icon-button icon-instagram"
                target="_blank"
                rel="noreferrer"
                aria-label="Home Assist Repairs and Servicing Instagram account"
              >
                <span className="hidden">
                  Home Assist Repairs and Servicing Instagram account
                </span>
              </a>
              <a
                href="https://www.linkedin.com/company/home-assist-repairs-and-servicing-ltd/"
                className="social-icon-button icon-linked-in"
                target="_blank"
                rel="noreferrer"
                aria-label="Home Assist Repairs and Servicing LinkedIn page"
              >
                <span className="hidden">
                  Home Assist Repairs and Servicing LinkedIn page
                </span>
              </a>
              <a
                href="https://twitter.com/repairHAservice"
                className="social-icon-button icon-twitter"
                target="_blank"
                rel="noreferrer"
                aria-label="Home Assist Repairs and Servicing Twitter account"
              >
                <span className="hidden">
                  Home Assist Repairs and Servicing Twitter account
                </span>
              </a>
            </li>
          </ul>
        </div>
        <FooterMinimal />
      </>
    )}
  />
)
