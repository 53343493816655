import React from "react"
import PropTypes from "prop-types"

export const LoaderContainer = ({ children }) => (
  <div className="loader-container">{children}</div>
)

LoaderContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
