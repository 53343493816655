import React from "react"
import Slider from "react-rangeslider"
import PropTypes from "prop-types"

export const RangeSlider = ({ maxValue, minValue, updateValue, value }) => (
  <div className="range-slider">
    <Slider
      value={value}
      orientation="horizontal"
      onChange={updateValue}
      min={minValue}
      max={maxValue}
      tooltip={false}
      handleLabel={value.toString()}
    />
  </div>
)

RangeSlider.propTypes = {
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  updateValue: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
}
