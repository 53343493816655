import React from "react"
import PropTypes from "prop-types"

export const ButtonWithLoader = ({ additionalClasses, children, ...props }) => (
  <button
    {...props}
    className={`button-primary button-with-loader${
      additionalClasses ? ` ${additionalClasses}` : ""
    }`}
  >
    <span className="button-with-loader-text">{children}</span>
  </button>
)

ButtonWithLoader.defaultProps = {
  additionalClasses: "",
}

ButtonWithLoader.propTypes = {
  additionalClasses: PropTypes.string,
  children: PropTypes.node.isRequired,
}
